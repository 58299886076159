import React, { useReducer, createContext, useEffect } from "react"
import { v4 as uuidv4 } from "uuid";
import acData from "../data/acuity_class_data.json";
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import {useAsync} from "react-async"
import * as queries from '../graphql/queries'

// const getClasses = async () => {
//   const gql_classes = await API.graphql({
//     query: queries.listAcuityClasss});
//   return gql_classes.data.listAcuityClasss.items;;
// };

export const AcuityClassContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "RELOAD_ACUITY_CLASSES": 
      return action.payload;
    case "ADD_ACUITY_CLASS":
      /* Creates new acuity class 
      Expected Payload: 
        name - string Acuity Class name 
        description - string description
      */
      const newPT = {
        key: uuidv4(),
        name: action.payload.name,
        description: action.payload.description
      };
      return [...state, newPT];
    case "DEL_ACUITY_CLASS":
      /* Deletes an acuity class 
      Expected Payload: 
        key - class identifying key
      */
      return [...state.filter(record => record.key !== action.payload.key)];
    case "EDIT_ACUITY_CLASS":
      /* Edits either the name or description of an existing acuity class
      Expected Payload: 
        acuityClass - string name 
        description - string description
        key - original class key 
      */
      let ids = [...state];
      let index = ids.findIndex(record => record.key === action.payload.key);
      ids[index] = action.payload;
      return ids;
    default:
      throw new Error();
  }
};

export const AcuityClassContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, []);

  // useEffect(async () => { 
  //   const data = await getClasses();
  //   dispatch({type:"RELOAD_ACUITY_CLASSES", payload:data});
  // }, []);

  return (
    <AcuityClassContext.Provider value={[state, dispatch]}>
      {props.children}
    </AcuityClassContext.Provider>
  );
};