/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSystem = /* GraphQL */ `
  query GetSystem($id: ID!) {
    getSystem(id: $id) {
      id
      name
      createdAt
      updatedAt
      branches {
        items {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSystems = /* GraphQL */ `
  query ListSystems(
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        branches {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSystemHistory = /* GraphQL */ `
  query GetSystemHistory($id: ID!) {
    getSystemHistory(id: $id) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      createdAt
      updatedAt
    }
  }
`;
export const listSystemHistorys = /* GraphQL */ `
  query ListSystemHistorys(
    $filter: ModelSystemHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventName
        eventTime
        eventARN
        objId
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBranch = /* GraphQL */ `
  query GetBranch($id: ID!) {
    getBranch(id: $id) {
      id
      name
      analystGroup
      createdAt
      updatedAt
      system {
        id
        name
        createdAt
        updatedAt
        branches {
          nextToken
        }
      }
      units {
        items {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listBranchs = /* GraphQL */ `
  query ListBranchs(
    $filter: ModelBranchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBranchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        analystGroup
        createdAt
        updatedAt
        system {
          id
          name
          createdAt
          updatedAt
        }
        units {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBranchHistory = /* GraphQL */ `
  query GetBranchHistory($id: ID!) {
    getBranchHistory(id: $id) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      branchSystemId
      createdAt
      updatedAt
    }
  }
`;
export const listBranchHistorys = /* GraphQL */ `
  query ListBranchHistorys(
    $filter: ModelBranchHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBranchHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventName
        eventTime
        eventARN
        objId
        name
        branchSystemId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUnit = /* GraphQL */ `
  query GetUnit($id: ID!) {
    getUnit(id: $id) {
      id
      name
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      branch {
        id
        name
        analystGroup
        createdAt
        updatedAt
        system {
          id
          name
          createdAt
          updatedAt
        }
        units {
          nextToken
        }
      }
      acuityClasses {
        items {
          id
          name
          description
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      staffClasses {
        items {
          id
          name
          maxPatients
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      associations {
        items {
          id
          capacity
          workload
          adminGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        items {
          id
          name
          canClerk
          isCharge
          activeUnit
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      beds {
        items {
          id
          name
          isOccupied
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUnits = /* GraphQL */ `
  query ListUnits(
    $filter: ModelUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUnitHistory = /* GraphQL */ `
  query GetUnitHistory($id: ID!) {
    getUnitHistory(id: $id) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      unitBranchId
      createdAt
      updatedAt
    }
  }
`;
export const listUnitHistorys = /* GraphQL */ `
  query ListUnitHistorys(
    $filter: ModelUnitHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnitHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventName
        eventTime
        eventARN
        objId
        name
        unitBranchId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAcuityClass = /* GraphQL */ `
  query GetAcuityClass($id: ID!) {
    getAcuityClass(id: $id) {
      id
      name
      description
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      canBeCaredForBy {
        items {
          id
          acuityId
          staffClassId
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listAcuityClasss = /* GraphQL */ `
  query ListAcuityClasss(
    $filter: ModelAcuityClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAcuityClasss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canBeCaredForBy {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const acuityClassByName = /* GraphQL */ `
  query AcuityClassByName(
    $name: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAcuityClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    acuityClassByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canBeCaredForBy {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAcuityClassHistory = /* GraphQL */ `
  query GetAcuityClassHistory($id: ID!) {
    getAcuityClassHistory(id: $id) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      description
      acuityClassUnitId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const listAcuityClassHistorys = /* GraphQL */ `
  query ListAcuityClassHistorys(
    $filter: ModelAcuityClassHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAcuityClassHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventName
        eventTime
        eventARN
        objId
        name
        description
        acuityClassUnitId
        createdAt
        updatedAt
        analystGroup
      }
      nextToken
    }
  }
`;
export const getStaffClass = /* GraphQL */ `
  query GetStaffClass($id: ID!) {
    getStaffClass(id: $id) {
      id
      name
      maxPatients
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      canCareFor {
        items {
          id
          acuityId
          staffClassId
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      assocations {
        items {
          id
          capacity
          workload
          adminGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        items {
          id
          name
          canClerk
          isCharge
          activeUnit
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listStaffClasss = /* GraphQL */ `
  query ListStaffClasss(
    $filter: ModelStaffClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffClasss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        maxPatients
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canCareFor {
          nextToken
        }
        assocations {
          nextToken
        }
        staff {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const staffClassByName = /* GraphQL */ `
  query StaffClassByName(
    $name: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffClassByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        maxPatients
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canCareFor {
          nextToken
        }
        assocations {
          nextToken
        }
        staff {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const staffClassByMaxPatients = /* GraphQL */ `
  query StaffClassByMaxPatients(
    $maxPatients: Int
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffClassByMaxPatients(
      maxPatients: $maxPatients
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        maxPatients
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canCareFor {
          nextToken
        }
        assocations {
          nextToken
        }
        staff {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getStaffClassHistory = /* GraphQL */ `
  query GetStaffClassHistory($id: ID!) {
    getStaffClassHistory(id: $id) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      maxPatients
      staffClassUnitId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const listStaffClassHistorys = /* GraphQL */ `
  query ListStaffClassHistorys(
    $filter: ModelStaffClassHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffClassHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventName
        eventTime
        eventARN
        objId
        name
        maxPatients
        staffClassUnitId
        createdAt
        updatedAt
        analystGroup
      }
      nextToken
    }
  }
`;
export const staffClassHistoryByName = /* GraphQL */ `
  query StaffClassHistoryByName(
    $name: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffClassHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffClassHistoryByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventName
        eventTime
        eventARN
        objId
        name
        maxPatients
        staffClassUnitId
        createdAt
        updatedAt
        analystGroup
      }
      nextToken
    }
  }
`;
export const staffClassHistoryByMaxPatients = /* GraphQL */ `
  query StaffClassHistoryByMaxPatients(
    $maxPatients: Int
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffClassHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffClassHistoryByMaxPatients(
      maxPatients: $maxPatients
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventName
        eventTime
        eventARN
        objId
        name
        maxPatients
        staffClassUnitId
        createdAt
        updatedAt
        analystGroup
      }
      nextToken
    }
  }
`;
export const getCanCareJoin = /* GraphQL */ `
  query GetCanCareJoin($id: ID!) {
    getCanCareJoin(id: $id) {
      id
      acuityId
      staffClassId
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      acuityClass {
        id
        name
        description
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canBeCaredForBy {
          nextToken
        }
      }
      staffClass {
        id
        name
        maxPatients
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canCareFor {
          nextToken
        }
        assocations {
          nextToken
        }
        staff {
          nextToken
        }
      }
    }
  }
`;
export const listCanCareJoins = /* GraphQL */ `
  query ListCanCareJoins(
    $filter: ModelCanCareJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCanCareJoins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        acuityId
        staffClassId
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        acuityClass {
          id
          name
          description
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        staffClass {
          id
          name
          maxPatients
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCanCareJoinHistory = /* GraphQL */ `
  query GetCanCareJoinHistory($id: ID!) {
    getCanCareJoinHistory(id: $id) {
      id
      eventName
      eventTime
      eventARN
      objId
      acuityId
      staffClassId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const listCanCareJoinHistorys = /* GraphQL */ `
  query ListCanCareJoinHistorys(
    $filter: ModelCanCareJoinHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCanCareJoinHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventName
        eventTime
        eventARN
        objId
        acuityId
        staffClassId
        createdAt
        updatedAt
        analystGroup
      }
      nextToken
    }
  }
`;
export const getAssociation = /* GraphQL */ `
  query GetAssociation($id: ID!) {
    getAssociation(id: $id) {
      id
      capacity
      workload
      adminGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      staffClass {
        id
        name
        maxPatients
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canCareFor {
          nextToken
        }
        assocations {
          nextToken
        }
        staff {
          nextToken
        }
      }
    }
  }
`;
export const listAssociations = /* GraphQL */ `
  query ListAssociations(
    $filter: ModelAssociationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssociations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        capacity
        workload
        adminGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        staffClass {
          id
          name
          maxPatients
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const associationByCapacity = /* GraphQL */ `
  query AssociationByCapacity(
    $capacity: Capacity
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssociationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    associationByCapacity(
      capacity: $capacity
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        capacity
        workload
        adminGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        staffClass {
          id
          name
          maxPatients
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAssociationHistory = /* GraphQL */ `
  query GetAssociationHistory($id: ID!) {
    getAssociationHistory(id: $id) {
      id
      eventName
      eventTime
      eventARN
      objId
      capacity
      workload
      associationUnitId
      associationStaffClassId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const listAssociationHistorys = /* GraphQL */ `
  query ListAssociationHistorys(
    $filter: ModelAssociationHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssociationHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventName
        eventTime
        eventARN
        objId
        capacity
        workload
        associationUnitId
        associationStaffClassId
        createdAt
        updatedAt
        analystGroup
      }
      nextToken
    }
  }
`;
export const associationHistoryByCapacity = /* GraphQL */ `
  query AssociationHistoryByCapacity(
    $capacity: Capacity
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssociationHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    associationHistoryByCapacity(
      capacity: $capacity
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventName
        eventTime
        eventARN
        objId
        capacity
        workload
        associationUnitId
        associationStaffClassId
        createdAt
        updatedAt
        analystGroup
      }
      nextToken
    }
  }
`;
export const getStaff = /* GraphQL */ `
  query GetStaff($id: ID!) {
    getStaff(id: $id) {
      id
      name
      canClerk
      isCharge
      activeUnit
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      staffClass {
        id
        name
        maxPatients
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canCareFor {
          nextToken
        }
        assocations {
          nextToken
        }
        staff {
          nextToken
        }
      }
    }
  }
`;
export const listStaffs = /* GraphQL */ `
  query ListStaffs(
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        canClerk
        isCharge
        activeUnit
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        staffClass {
          id
          name
          maxPatients
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStaffHistory = /* GraphQL */ `
  query GetStaffHistory($id: ID!) {
    getStaffHistory(id: $id) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      canClerk
      isCharge
      activeUnit
      staffStaffClassId
      staffUnitId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const listStaffHistorys = /* GraphQL */ `
  query ListStaffHistorys(
    $filter: ModelStaffHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventName
        eventTime
        eventARN
        objId
        name
        canClerk
        isCharge
        activeUnit
        staffStaffClassId
        staffUnitId
        createdAt
        updatedAt
        analystGroup
      }
      nextToken
    }
  }
`;
export const getBed = /* GraphQL */ `
  query GetBed($id: ID!) {
    getBed(id: $id) {
      id
      name
      isOccupied
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      acuityClass {
        id
        name
        description
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canBeCaredForBy {
          nextToken
        }
      }
    }
  }
`;
export const listBeds = /* GraphQL */ `
  query ListBeds(
    $filter: ModelBedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        isOccupied
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        acuityClass {
          id
          name
          description
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const bedByName = /* GraphQL */ `
  query BedByName(
    $name: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bedByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        isOccupied
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        acuityClass {
          id
          name
          description
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getBedHistory = /* GraphQL */ `
  query GetBedHistory($id: ID!) {
    getBedHistory(id: $id) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      isOccupied
      bedAcuityClassId
      bedUnitId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const listBedHistorys = /* GraphQL */ `
  query ListBedHistorys(
    $filter: ModelBedHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBedHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventName
        eventTime
        eventARN
        objId
        name
        isOccupied
        bedAcuityClassId
        bedUnitId
        createdAt
        updatedAt
        analystGroup
      }
      nextToken
    }
  }
`;
