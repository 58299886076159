import React, { useReducer, createContext } from "react"
import { v4 as uuidv4 } from "uuid";
import staffData from "../data/staff_data.json";

export const StaffTypeContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_STAFF_TYPE":
      /* Creates new staff class 
      Expected Payload: 
        staffType - string name 
        maxPt - int, need to divide by 20 to account for spacing in original form 
        acuityClasses - list of strings
      */
      const newStaffType = {
        key: uuidv4(),
        staffType: action.payload.staffType,
        /*In order to ger the spacing right, the slider is at 20pt intervals
        so to get the value we have to divide by 20*/
        maxPt: action.payload.maxPt > 0 ? action.payload.maxPt / 20 : 0,
        acuityClasses: action.payload.acuityClasses
      };
      return [...state, newStaffType];
    case "DEL_STAFF_TYPE":
      /* Deletes staff type
      Expected Payload: 
        key - staff type key 
      */
      return [...state.filter(record => record.key !== action.payload.key)];
    case "EDIT_STAFF_TYPE":
    /* Edits existing staff type 
      Expected Payload: 
        staffType - string name 
        maxPt - int, need to divide by 20 to account for spacing in original form 
        acuityClasses - list of strings
        key -key of original staff type being edited
      */
      let ids = [...state];
      let index = ids.findIndex(record => record.key === action.payload.key);
      ids[index] = action.payload;
      return ids;
    default:
      throw new Error();
  }
};

export const StaffTypeContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, staffData);

  return (
    <StaffTypeContext.Provider value={[state, dispatch]}>
      {props.children}
    </StaffTypeContext.Provider>
  );
};