import React, { Component, useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { StaffTypeContextProvider } from "./contexts/staff_type_context";
import { AcuityClassContextProvider } from "./contexts/acuity_class_context";
import { ActiveStaffContextProvider } from "./contexts/active_staff_context";
import { ActiveBedsContextProvider } from "./contexts/active_beds_context";
import './scss/style.scss';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import { ApolloClient, from, gql, HttpLink, InMemoryCache, split, ApolloProvider } from "@apollo/client";
import { createHttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
//import Auth from '@aws-amplify/auth';
import { AUTH_TYPE, createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsmobile from './aws-exports';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import * as subscriptions from './graphql/subscriptions';

Amplify.configure(awsmobile);



const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));


const App = props => {
  return (
    <HashRouter>
      <AcuityClassContextProvider>
        <StaffTypeContextProvider>
          <ActiveStaffContextProvider>
            <ActiveBedsContextProvider>
              <React.Suspense fallback={loading}>
                <Switch>
                  <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                  <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
                  <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                  <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                  <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
                </Switch>
              </React.Suspense>
            </ActiveBedsContextProvider>
          </ActiveStaffContextProvider>
        </StaffTypeContextProvider>
      </AcuityClassContextProvider>
    </HashRouter>
  );
}

const url = awsmobile.aws_appsync_graphqlEndpoint;
const region = awsmobile.aws_appsync_region;
const auth =  {
  type: awsmobile.aws_appsync_authenticationType,
  jwtToken: async () => {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  }
}
// const auth = {
//   type: awsconfig.authenticationType,
//   apiKey: awsconfig.apiKey
// };
const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createHttpLink({ uri: url })
]);
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(), 
  connectToDevTools: true
});

const WithProvider = () => (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

export default withAuthenticator(WithProvider);
//export default WithProvider;
