import React, { useReducer, createContext } from "react"
import { v4 as uuidv4 } from "uuid";
import activeBedsData from "../data/active_beds_data.json";

export const ActiveBedsContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_BED":
      /* Creates new bed 
      Expected Payload Data: 
        - bedNumber -> Label the bed will apppear with 
      */
      const newBed = {
        key: uuidv4(),
        bedNumber: action.payload.bedNumber
      };
      return {
        allBeds: [...state.allBeds, newBed],
        activeBeds: [...state.activeBeds]
      };
    case "DEL_BED": 
    /* Deletes a bed
    Expected Payload Data: 
      - key -> Sepecific bed Key 
    As a note, should talk about error handling since this currently also 
    remove any patients assigned to the bed when the bed is deleted 
    */
      return {
        allBeds: [...state.allBeds.filter(record => record.key !== action.payload.key)],
        activeBeds: [...state.activeBeds.filter(record => record.key !== action.payload.key)]
      };

    case "EDIT_BED":
    /* Edits a bed number/how it's display 
    Expected Payload Data: 
      - key -> Specific bed key 
      - bedNumber -> new bed number 
    Note will update the number in the main list of beds
    and all patient associations as well 
    */
      let allBedsIndex = state.allBeds.findIndex(record => record.key===action.payload.key);
      let activeBedsIndex = state.activeBeds.findIndex(record => record.key===action.payload.key);

      var all = state.allBeds;
      var active = state.activeBeds;

      all[allBedsIndex] = action.payload;
      if (activeBedsIndex > -1) { 
        active[activeBedsIndex].bedNumber = action.payload.bedNumber;
      }
      return { 
        activeBeds: active, 
        allBeds: all
      };

    case "ADMIT_PATIENT":
    /* Admits a new patient to a given bed
    Patients are given a randomly generated ID
    Expected Payload: 
      bedNumber - Bed number being admitted to 
      key - unique key of the bed 
      acuityClass - acuity class of the patient as they're admitted 
    */
      let bedIndex = state.allBeds.findIndex(record => record.bedNumber === action.payload.bedNumber.value);
      const bedKey = state.allBeds[bedIndex].key;
      const newPT = {
        key: bedKey,
        patientKey: uuidv4(),
        bedNumber: action.payload.bedNumber,
        acuityClass: action.payload.acuityClass
      };
      return {
        activeBeds: [...state.activeBeds, newPT],
        allBeds: [...state.allBeds]
      };
    case "DISCHARGE_PATIENT":
    /* discharges a patient by removing the bed from active
    Expected Payload:
      key - bed Key 
    */
      return {
        allBeds: [...state.allBeds],
        activeBeds: [...state.activeBeds.filter(record => record.key !== action.payload.key)]
      };
    case "EDIT_STATUS":
    /* Edits the status of an active bed/patient 
    Expected Input
      key - bed key 
      acuityClass - new acuity class 
      bedNumber - bed number 
      patientKey - random key generated for patient on creation
    */
      let ids = [...state.activeBeds];
      let index = ids.findIndex(record => record.key === action.payload.key);
      ids[index] = action.payload;
      return { allBeds: [...state.allBeds], activeBeds: ids };
    default:
      throw new Error();
  }
};

export const ActiveBedsContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, {
    allBeds: [],
    activeBeds: activeBedsData
  });

  return (
    <ActiveBedsContext.Provider value={[state, dispatch]}>
      {props.children}
    </ActiveBedsContext.Provider>
  );
};