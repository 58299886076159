/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addStaff = /* GraphQL */ `
  mutation AddStaff($input: AddStaffInput!) {
    addStaff(input: $input) {
      id
      name
      canClerk
      isCharge
      activeUnit
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      staffClass {
        id
        name
        maxPatients
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canCareFor {
          nextToken
        }
        assocations {
          nextToken
        }
        staff {
          nextToken
        }
      }
    }
  }
`;
export const addBed = /* GraphQL */ `
  mutation AddBed($input: AddBedInput!) {
    addBed(input: $input) {
      id
      name
      isOccupied
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      acuityClass {
        id
        name
        description
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canBeCaredForBy {
          nextToken
        }
      }
    }
  }
`;
export const addStaffClass = /* GraphQL */ `
  mutation AddStaffClass($input: AddStaffClassInput!) {
    addStaffClass(input: $input) {
      id
      name
      maxPatients
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      canCareFor {
        items {
          id
          acuityId
          staffClassId
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      assocations {
        items {
          id
          capacity
          workload
          adminGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        items {
          id
          name
          canClerk
          isCharge
          activeUnit
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const addAcuityClass = /* GraphQL */ `
  mutation AddAcuityClass($input: AddAcuityClassInput!) {
    addAcuityClass(input: $input) {
      id
      name
      description
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      canBeCaredForBy {
        items {
          id
          acuityId
          staffClassId
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const addSystem = /* GraphQL */ `
  mutation AddSystem($input: AddSystemInput!) {
    addSystem(input: $input) {
      id
      name
      createdAt
      updatedAt
      branches {
        items {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const addBranch = /* GraphQL */ `
  mutation AddBranch($input: AddBranchInput!) {
    addBranch(input: $input) {
      id
      name
      analystGroup
      createdAt
      updatedAt
      system {
        id
        name
        createdAt
        updatedAt
        branches {
          nextToken
        }
      }
      units {
        items {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const addUnit = /* GraphQL */ `
  mutation AddUnit($input: AddUnitInput!) {
    addUnit(input: $input) {
      id
      name
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      branch {
        id
        name
        analystGroup
        createdAt
        updatedAt
        system {
          id
          name
          createdAt
          updatedAt
        }
        units {
          nextToken
        }
      }
      acuityClasses {
        items {
          id
          name
          description
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      staffClasses {
        items {
          id
          name
          maxPatients
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      associations {
        items {
          id
          capacity
          workload
          adminGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        items {
          id
          name
          canClerk
          isCharge
          activeUnit
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      beds {
        items {
          id
          name
          isOccupied
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const removeSystem = /* GraphQL */ `
  mutation RemoveSystem($input: RemoveSystemInput!) {
    removeSystem(input: $input) {
      id
      name
      createdAt
      updatedAt
      branches {
        items {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const removeUnit = /* GraphQL */ `
  mutation RemoveUnit($input: RemoveUnitInput!) {
    removeUnit(input: $input) {
      id
      name
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      branch {
        id
        name
        analystGroup
        createdAt
        updatedAt
        system {
          id
          name
          createdAt
          updatedAt
        }
        units {
          nextToken
        }
      }
      acuityClasses {
        items {
          id
          name
          description
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      staffClasses {
        items {
          id
          name
          maxPatients
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      associations {
        items {
          id
          capacity
          workload
          adminGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        items {
          id
          name
          canClerk
          isCharge
          activeUnit
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      beds {
        items {
          id
          name
          isOccupied
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSystem = /* GraphQL */ `
  mutation CreateSystem(
    $input: CreateSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    createSystem(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      branches {
        items {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSystem = /* GraphQL */ `
  mutation UpdateSystem(
    $input: UpdateSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    updateSystem(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      branches {
        items {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSystem = /* GraphQL */ `
  mutation DeleteSystem(
    $input: DeleteSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    deleteSystem(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      branches {
        items {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSystemHistory = /* GraphQL */ `
  mutation CreateSystemHistory(
    $input: CreateSystemHistoryInput!
    $condition: ModelSystemHistoryConditionInput
  ) {
    createSystemHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateSystemHistory = /* GraphQL */ `
  mutation UpdateSystemHistory(
    $input: UpdateSystemHistoryInput!
    $condition: ModelSystemHistoryConditionInput
  ) {
    updateSystemHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteSystemHistory = /* GraphQL */ `
  mutation DeleteSystemHistory(
    $input: DeleteSystemHistoryInput!
    $condition: ModelSystemHistoryConditionInput
  ) {
    deleteSystemHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      createdAt
      updatedAt
    }
  }
`;
export const createBranch = /* GraphQL */ `
  mutation CreateBranch(
    $input: CreateBranchInput!
    $condition: ModelBranchConditionInput
  ) {
    createBranch(input: $input, condition: $condition) {
      id
      name
      analystGroup
      createdAt
      updatedAt
      system {
        id
        name
        createdAt
        updatedAt
        branches {
          nextToken
        }
      }
      units {
        items {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateBranch = /* GraphQL */ `
  mutation UpdateBranch(
    $input: UpdateBranchInput!
    $condition: ModelBranchConditionInput
  ) {
    updateBranch(input: $input, condition: $condition) {
      id
      name
      analystGroup
      createdAt
      updatedAt
      system {
        id
        name
        createdAt
        updatedAt
        branches {
          nextToken
        }
      }
      units {
        items {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteBranch = /* GraphQL */ `
  mutation DeleteBranch(
    $input: DeleteBranchInput!
    $condition: ModelBranchConditionInput
  ) {
    deleteBranch(input: $input, condition: $condition) {
      id
      name
      analystGroup
      createdAt
      updatedAt
      system {
        id
        name
        createdAt
        updatedAt
        branches {
          nextToken
        }
      }
      units {
        items {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createBranchHistory = /* GraphQL */ `
  mutation CreateBranchHistory(
    $input: CreateBranchHistoryInput!
    $condition: ModelBranchHistoryConditionInput
  ) {
    createBranchHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      branchSystemId
      createdAt
      updatedAt
    }
  }
`;
export const updateBranchHistory = /* GraphQL */ `
  mutation UpdateBranchHistory(
    $input: UpdateBranchHistoryInput!
    $condition: ModelBranchHistoryConditionInput
  ) {
    updateBranchHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      branchSystemId
      createdAt
      updatedAt
    }
  }
`;
export const deleteBranchHistory = /* GraphQL */ `
  mutation DeleteBranchHistory(
    $input: DeleteBranchHistoryInput!
    $condition: ModelBranchHistoryConditionInput
  ) {
    deleteBranchHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      branchSystemId
      createdAt
      updatedAt
    }
  }
`;
export const createUnit = /* GraphQL */ `
  mutation CreateUnit(
    $input: CreateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    createUnit(input: $input, condition: $condition) {
      id
      name
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      branch {
        id
        name
        analystGroup
        createdAt
        updatedAt
        system {
          id
          name
          createdAt
          updatedAt
        }
        units {
          nextToken
        }
      }
      acuityClasses {
        items {
          id
          name
          description
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      staffClasses {
        items {
          id
          name
          maxPatients
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      associations {
        items {
          id
          capacity
          workload
          adminGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        items {
          id
          name
          canClerk
          isCharge
          activeUnit
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      beds {
        items {
          id
          name
          isOccupied
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUnit = /* GraphQL */ `
  mutation UpdateUnit(
    $input: UpdateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    updateUnit(input: $input, condition: $condition) {
      id
      name
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      branch {
        id
        name
        analystGroup
        createdAt
        updatedAt
        system {
          id
          name
          createdAt
          updatedAt
        }
        units {
          nextToken
        }
      }
      acuityClasses {
        items {
          id
          name
          description
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      staffClasses {
        items {
          id
          name
          maxPatients
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      associations {
        items {
          id
          capacity
          workload
          adminGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        items {
          id
          name
          canClerk
          isCharge
          activeUnit
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      beds {
        items {
          id
          name
          isOccupied
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUnit = /* GraphQL */ `
  mutation DeleteUnit(
    $input: DeleteUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    deleteUnit(input: $input, condition: $condition) {
      id
      name
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      branch {
        id
        name
        analystGroup
        createdAt
        updatedAt
        system {
          id
          name
          createdAt
          updatedAt
        }
        units {
          nextToken
        }
      }
      acuityClasses {
        items {
          id
          name
          description
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      staffClasses {
        items {
          id
          name
          maxPatients
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      associations {
        items {
          id
          capacity
          workload
          adminGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        items {
          id
          name
          canClerk
          isCharge
          activeUnit
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      beds {
        items {
          id
          name
          isOccupied
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUnitHistory = /* GraphQL */ `
  mutation CreateUnitHistory(
    $input: CreateUnitHistoryInput!
    $condition: ModelUnitHistoryConditionInput
  ) {
    createUnitHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      unitBranchId
      createdAt
      updatedAt
    }
  }
`;
export const updateUnitHistory = /* GraphQL */ `
  mutation UpdateUnitHistory(
    $input: UpdateUnitHistoryInput!
    $condition: ModelUnitHistoryConditionInput
  ) {
    updateUnitHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      unitBranchId
      createdAt
      updatedAt
    }
  }
`;
export const deleteUnitHistory = /* GraphQL */ `
  mutation DeleteUnitHistory(
    $input: DeleteUnitHistoryInput!
    $condition: ModelUnitHistoryConditionInput
  ) {
    deleteUnitHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      unitBranchId
      createdAt
      updatedAt
    }
  }
`;
export const createAcuityClass = /* GraphQL */ `
  mutation CreateAcuityClass(
    $input: CreateAcuityClassInput!
    $condition: ModelAcuityClassConditionInput
  ) {
    createAcuityClass(input: $input, condition: $condition) {
      id
      name
      description
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      canBeCaredForBy {
        items {
          id
          acuityId
          staffClassId
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAcuityClass = /* GraphQL */ `
  mutation UpdateAcuityClass(
    $input: UpdateAcuityClassInput!
    $condition: ModelAcuityClassConditionInput
  ) {
    updateAcuityClass(input: $input, condition: $condition) {
      id
      name
      description
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      canBeCaredForBy {
        items {
          id
          acuityId
          staffClassId
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteAcuityClass = /* GraphQL */ `
  mutation DeleteAcuityClass(
    $input: DeleteAcuityClassInput!
    $condition: ModelAcuityClassConditionInput
  ) {
    deleteAcuityClass(input: $input, condition: $condition) {
      id
      name
      description
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      canBeCaredForBy {
        items {
          id
          acuityId
          staffClassId
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAcuityClassHistory = /* GraphQL */ `
  mutation UpdateAcuityClassHistory(
    $input: UpdateAcuityClassHistoryInput!
    $condition: ModelAcuityClassHistoryConditionInput
  ) {
    updateAcuityClassHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      description
      acuityClassUnitId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const deleteAcuityClassHistory = /* GraphQL */ `
  mutation DeleteAcuityClassHistory(
    $input: DeleteAcuityClassHistoryInput!
    $condition: ModelAcuityClassHistoryConditionInput
  ) {
    deleteAcuityClassHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      description
      acuityClassUnitId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const createAcuityClassHistory = /* GraphQL */ `
  mutation CreateAcuityClassHistory(
    $input: CreateAcuityClassHistoryInput!
    $condition: ModelAcuityClassHistoryConditionInput
  ) {
    createAcuityClassHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      description
      acuityClassUnitId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const createStaffClass = /* GraphQL */ `
  mutation CreateStaffClass(
    $input: CreateStaffClassInput!
    $condition: ModelStaffClassConditionInput
  ) {
    createStaffClass(input: $input, condition: $condition) {
      id
      name
      maxPatients
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      canCareFor {
        items {
          id
          acuityId
          staffClassId
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      assocations {
        items {
          id
          capacity
          workload
          adminGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        items {
          id
          name
          canClerk
          isCharge
          activeUnit
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateStaffClass = /* GraphQL */ `
  mutation UpdateStaffClass(
    $input: UpdateStaffClassInput!
    $condition: ModelStaffClassConditionInput
  ) {
    updateStaffClass(input: $input, condition: $condition) {
      id
      name
      maxPatients
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      canCareFor {
        items {
          id
          acuityId
          staffClassId
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      assocations {
        items {
          id
          capacity
          workload
          adminGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        items {
          id
          name
          canClerk
          isCharge
          activeUnit
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteStaffClass = /* GraphQL */ `
  mutation DeleteStaffClass(
    $input: DeleteStaffClassInput!
    $condition: ModelStaffClassConditionInput
  ) {
    deleteStaffClass(input: $input, condition: $condition) {
      id
      name
      maxPatients
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      canCareFor {
        items {
          id
          acuityId
          staffClassId
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      assocations {
        items {
          id
          capacity
          workload
          adminGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        items {
          id
          name
          canClerk
          isCharge
          activeUnit
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateStaffClassHistory = /* GraphQL */ `
  mutation UpdateStaffClassHistory(
    $input: UpdateStaffClassHistoryInput!
    $condition: ModelStaffClassHistoryConditionInput
  ) {
    updateStaffClassHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      maxPatients
      staffClassUnitId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const deleteStaffClassHistory = /* GraphQL */ `
  mutation DeleteStaffClassHistory(
    $input: DeleteStaffClassHistoryInput!
    $condition: ModelStaffClassHistoryConditionInput
  ) {
    deleteStaffClassHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      maxPatients
      staffClassUnitId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const createStaffClassHistory = /* GraphQL */ `
  mutation CreateStaffClassHistory(
    $input: CreateStaffClassHistoryInput!
    $condition: ModelStaffClassHistoryConditionInput
  ) {
    createStaffClassHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      maxPatients
      staffClassUnitId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const createCanCareJoin = /* GraphQL */ `
  mutation CreateCanCareJoin(
    $input: CreateCanCareJoinInput!
    $condition: ModelCanCareJoinConditionInput
  ) {
    createCanCareJoin(input: $input, condition: $condition) {
      id
      acuityId
      staffClassId
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      acuityClass {
        id
        name
        description
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canBeCaredForBy {
          nextToken
        }
      }
      staffClass {
        id
        name
        maxPatients
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canCareFor {
          nextToken
        }
        assocations {
          nextToken
        }
        staff {
          nextToken
        }
      }
    }
  }
`;
export const updateCanCareJoin = /* GraphQL */ `
  mutation UpdateCanCareJoin(
    $input: UpdateCanCareJoinInput!
    $condition: ModelCanCareJoinConditionInput
  ) {
    updateCanCareJoin(input: $input, condition: $condition) {
      id
      acuityId
      staffClassId
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      acuityClass {
        id
        name
        description
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canBeCaredForBy {
          nextToken
        }
      }
      staffClass {
        id
        name
        maxPatients
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canCareFor {
          nextToken
        }
        assocations {
          nextToken
        }
        staff {
          nextToken
        }
      }
    }
  }
`;
export const deleteCanCareJoin = /* GraphQL */ `
  mutation DeleteCanCareJoin(
    $input: DeleteCanCareJoinInput!
    $condition: ModelCanCareJoinConditionInput
  ) {
    deleteCanCareJoin(input: $input, condition: $condition) {
      id
      acuityId
      staffClassId
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      acuityClass {
        id
        name
        description
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canBeCaredForBy {
          nextToken
        }
      }
      staffClass {
        id
        name
        maxPatients
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canCareFor {
          nextToken
        }
        assocations {
          nextToken
        }
        staff {
          nextToken
        }
      }
    }
  }
`;
export const updateCanCareJoinHistory = /* GraphQL */ `
  mutation UpdateCanCareJoinHistory(
    $input: UpdateCanCareJoinHistoryInput!
    $condition: ModelCanCareJoinHistoryConditionInput
  ) {
    updateCanCareJoinHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      acuityId
      staffClassId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const deleteCanCareJoinHistory = /* GraphQL */ `
  mutation DeleteCanCareJoinHistory(
    $input: DeleteCanCareJoinHistoryInput!
    $condition: ModelCanCareJoinHistoryConditionInput
  ) {
    deleteCanCareJoinHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      acuityId
      staffClassId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const createCanCareJoinHistory = /* GraphQL */ `
  mutation CreateCanCareJoinHistory(
    $input: CreateCanCareJoinHistoryInput!
    $condition: ModelCanCareJoinHistoryConditionInput
  ) {
    createCanCareJoinHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      acuityId
      staffClassId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const createAssociation = /* GraphQL */ `
  mutation CreateAssociation(
    $input: CreateAssociationInput!
    $condition: ModelAssociationConditionInput
  ) {
    createAssociation(input: $input, condition: $condition) {
      id
      capacity
      workload
      adminGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      staffClass {
        id
        name
        maxPatients
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canCareFor {
          nextToken
        }
        assocations {
          nextToken
        }
        staff {
          nextToken
        }
      }
    }
  }
`;
export const updateAssociation = /* GraphQL */ `
  mutation UpdateAssociation(
    $input: UpdateAssociationInput!
    $condition: ModelAssociationConditionInput
  ) {
    updateAssociation(input: $input, condition: $condition) {
      id
      capacity
      workload
      adminGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      staffClass {
        id
        name
        maxPatients
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canCareFor {
          nextToken
        }
        assocations {
          nextToken
        }
        staff {
          nextToken
        }
      }
    }
  }
`;
export const deleteAssociation = /* GraphQL */ `
  mutation DeleteAssociation(
    $input: DeleteAssociationInput!
    $condition: ModelAssociationConditionInput
  ) {
    deleteAssociation(input: $input, condition: $condition) {
      id
      capacity
      workload
      adminGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      staffClass {
        id
        name
        maxPatients
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canCareFor {
          nextToken
        }
        assocations {
          nextToken
        }
        staff {
          nextToken
        }
      }
    }
  }
`;
export const updateAssociationHistory = /* GraphQL */ `
  mutation UpdateAssociationHistory(
    $input: UpdateAssociationHistoryInput!
    $condition: ModelAssociationHistoryConditionInput
  ) {
    updateAssociationHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      capacity
      workload
      associationUnitId
      associationStaffClassId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const deleteAssociationHistory = /* GraphQL */ `
  mutation DeleteAssociationHistory(
    $input: DeleteAssociationHistoryInput!
    $condition: ModelAssociationHistoryConditionInput
  ) {
    deleteAssociationHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      capacity
      workload
      associationUnitId
      associationStaffClassId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const createAssociationHistory = /* GraphQL */ `
  mutation CreateAssociationHistory(
    $input: CreateAssociationHistoryInput!
    $condition: ModelAssociationHistoryConditionInput
  ) {
    createAssociationHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      capacity
      workload
      associationUnitId
      associationStaffClassId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const createStaff = /* GraphQL */ `
  mutation CreateStaff(
    $input: CreateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    createStaff(input: $input, condition: $condition) {
      id
      name
      canClerk
      isCharge
      activeUnit
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      staffClass {
        id
        name
        maxPatients
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canCareFor {
          nextToken
        }
        assocations {
          nextToken
        }
        staff {
          nextToken
        }
      }
    }
  }
`;
export const updateStaff = /* GraphQL */ `
  mutation UpdateStaff(
    $input: UpdateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    updateStaff(input: $input, condition: $condition) {
      id
      name
      canClerk
      isCharge
      activeUnit
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      staffClass {
        id
        name
        maxPatients
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canCareFor {
          nextToken
        }
        assocations {
          nextToken
        }
        staff {
          nextToken
        }
      }
    }
  }
`;
export const deleteStaff = /* GraphQL */ `
  mutation DeleteStaff(
    $input: DeleteStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    deleteStaff(input: $input, condition: $condition) {
      id
      name
      canClerk
      isCharge
      activeUnit
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      staffClass {
        id
        name
        maxPatients
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canCareFor {
          nextToken
        }
        assocations {
          nextToken
        }
        staff {
          nextToken
        }
      }
    }
  }
`;
export const updateStaffHistory = /* GraphQL */ `
  mutation UpdateStaffHistory(
    $input: UpdateStaffHistoryInput!
    $condition: ModelStaffHistoryConditionInput
  ) {
    updateStaffHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      canClerk
      isCharge
      activeUnit
      staffStaffClassId
      staffUnitId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const deleteStaffHistory = /* GraphQL */ `
  mutation DeleteStaffHistory(
    $input: DeleteStaffHistoryInput!
    $condition: ModelStaffHistoryConditionInput
  ) {
    deleteStaffHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      canClerk
      isCharge
      activeUnit
      staffStaffClassId
      staffUnitId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const createStaffHistory = /* GraphQL */ `
  mutation CreateStaffHistory(
    $input: CreateStaffHistoryInput!
    $condition: ModelStaffHistoryConditionInput
  ) {
    createStaffHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      canClerk
      isCharge
      activeUnit
      staffStaffClassId
      staffUnitId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const createBed = /* GraphQL */ `
  mutation CreateBed(
    $input: CreateBedInput!
    $condition: ModelBedConditionInput
  ) {
    createBed(input: $input, condition: $condition) {
      id
      name
      isOccupied
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      acuityClass {
        id
        name
        description
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canBeCaredForBy {
          nextToken
        }
      }
    }
  }
`;
export const updateBed = /* GraphQL */ `
  mutation UpdateBed(
    $input: UpdateBedInput!
    $condition: ModelBedConditionInput
  ) {
    updateBed(input: $input, condition: $condition) {
      id
      name
      isOccupied
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      acuityClass {
        id
        name
        description
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canBeCaredForBy {
          nextToken
        }
      }
    }
  }
`;
export const deleteBed = /* GraphQL */ `
  mutation DeleteBed(
    $input: DeleteBedInput!
    $condition: ModelBedConditionInput
  ) {
    deleteBed(input: $input, condition: $condition) {
      id
      name
      isOccupied
      adminGroup
      clerkGroup
      analystGroup
      createdAt
      updatedAt
      unit {
        id
        name
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        branch {
          id
          name
          analystGroup
          createdAt
          updatedAt
        }
        acuityClasses {
          nextToken
        }
        staffClasses {
          nextToken
        }
        associations {
          nextToken
        }
        staff {
          nextToken
        }
        beds {
          nextToken
        }
      }
      acuityClass {
        id
        name
        description
        adminGroup
        clerkGroup
        analystGroup
        createdAt
        updatedAt
        unit {
          id
          name
          adminGroup
          clerkGroup
          analystGroup
          createdAt
          updatedAt
        }
        canBeCaredForBy {
          nextToken
        }
      }
    }
  }
`;
export const updateBedHistory = /* GraphQL */ `
  mutation UpdateBedHistory(
    $input: UpdateBedHistoryInput!
    $condition: ModelBedHistoryConditionInput
  ) {
    updateBedHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      isOccupied
      bedAcuityClassId
      bedUnitId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const deleteBedHistory = /* GraphQL */ `
  mutation DeleteBedHistory(
    $input: DeleteBedHistoryInput!
    $condition: ModelBedHistoryConditionInput
  ) {
    deleteBedHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      isOccupied
      bedAcuityClassId
      bedUnitId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
export const createBedHistory = /* GraphQL */ `
  mutation CreateBedHistory(
    $input: CreateBedHistoryInput!
    $condition: ModelBedHistoryConditionInput
  ) {
    createBedHistory(input: $input, condition: $condition) {
      id
      eventName
      eventTime
      eventARN
      objId
      name
      isOccupied
      bedAcuityClassId
      bedUnitId
      createdAt
      updatedAt
      analystGroup
    }
  }
`;
