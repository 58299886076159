import React, { useReducer, createContext } from "react"
import staffRosterData from "../data/staff_roster_data.json";
import { v4 as uuidv4 } from "uuid";

export const ActiveStaffContext = createContext();

const reducer = (state, action) => {
    switch (action.type) {
        case "ADD_ACTIVE":
            const newActiveStaff = {
                key: action.payload.key,
                staffType: action.payload.staffType,
                staffName: action.payload.staffName,
                charge: false
            };
            return {
                activeStaff: [...state.activeStaff, newActiveStaff],
                roster: [...state.roster.filter(record => record.key !== action.payload.key)]
            };
        case "ADD_TO_ROSTER":
            const newStaff = {
                key: uuidv4(),
                staffType: action.payload.staffType,
                staffName: action.payload.staffName,
            };
            return {
                activeStaff: [...state.activeStaff],
                roster: [...state.roster, newStaff]
            };
        case "DEL_ACTIVE":
            const delActiveStaff = {
                key: action.payload.key,
                staffType: action.payload.staffType,
                staffName: action.payload.staffName,
            };
            return {
                roster: [...state.roster, delActiveStaff],
                activeStaff: [...state.activeStaff.filter(record => record.key !== action.payload.key)]
            };
        case "DEL_FROM_ROSTER":
            return {
                roster: [...state.roster.filter(record => record.key !== action.payload.key)],
                activeStaff: [...state.activeStaff]
            };
        case "EDIT_CHARGE":
            let index = state.activeStaff.findIndex(tm => tm.key === action.payload.key);
            var active = state.activeStaff;
            active[index] = action.payload;
            return {
                activeStaff: active,
                roster: [...state.roster]
            }
        case "EDIT_STAFF_MEMBER":
            let staff_index = state.roster.findIndex(tm => tm.key === action.payload.key);
            var roster = state.roster;
            roster[staff_index] = action.payload;
            return {
                activeStaff: [...state.activeStaff],
                roster: roster
            }
        default:
            throw new Error();
    }
};

export const ActiveStaffContextProvider = props => {
    const [state, dispatch] = useReducer(reducer,
        {
            roster: staffRosterData,
            activeStaff: [{
                staffName: "test",
                staffType: "test",
                key: 9,
                charge: false
            }]
        });

    return (
        <ActiveStaffContext.Provider value={[state, dispatch]}>
            {props.children}
        </ActiveStaffContext.Provider>
    );
};